import React from "react";
import BackgroundChecks from "../../../components/Landers/BackgroundChecks";
import Banner from "../../../components/Landers/Banner";
import Brands from "../../../components/Landers/Brands";
import CustomizedSolutions from "../../../components/Landers/CustomizedSolutions";
import Footer from "../../../components/Landers/Footer";
import GetStarted from "../../../components/Landers/GetStarted";
import Header from "../../../components/Landers/Header";
import styles from "../../../components/Landers/landers.module.scss";
import SEO from "../../../components/Landers/SEO";
import Testimonials from "../../../components/Landers/Testimonials";
import AllCheck from "../../../components/Landers/AllCheck";
import DataInfo from "../../../components/Data";

const solutions2 = [
  {
    h5: "For Nurses, Medical Assistants, and Home Health Aide",
    p:
      "Identify candidate’s involvement in any past misconduct, sexual offense, drug abuse, or criminal cases that could put patients, staff, and the organization at risk.",
  },
  {
    h5: "For Physicians and Medical Practitioners",
    p:
      "Examine the aspects which are critical for a trusted physician. Verify their education, employment, drug history, licensing, and criminal background history to ensure your patient’s health and your reputation is not at stake.",
  },
  {
    h5: "For Students Practitioners",
    p:
      "You want to give your students every edge you can. Therefore, ensure your best and brightest meet the required standards set for the healthcare facilities before you admit them.",
  },
];

const customized = [
  {
    h4: "Built-in compliance \nworkflows",
    p:
      "SpringVerify runs checks on required healthcare sanctions and abuse registries to find any discrepancies on the candidate. In addition, ensure you adhere to all screening regulations and compliance standards.",
  },
  {
    h4: "Empower Bulk and \nSeasonal Hiring ",
    p:
      "Verify multiple candidates in one shot. Easily, easily set your hiring criteria and screen candidates based on your requirements.",
  },
  {
    h4: "Seamless Candidate \nExperience",
    p:
      "Candidates can submit the information quickly and track the status in real-time. They can also connect with our live support team in case of any queries.",
  },
];

const testimonials = [
  {
    name: "Mariam M",
    company: "Touchkin eServices Pvt. Ltd.",
    p:
      "It's the first time we are doing background checks. But still, SpringVerify gave us very good clarity on the process. They helped us in setting up the account quickly and and helped us perform checks without any hassle.",
  },
  {
    name: "Manvi K",
    company: "Inshorts",
    p:
      "One thing that really stands-out is their GREAT customer service. The Dedicated Account Manager responds in a very timely manner to any question at the drop of a hat and you really can't ask for more than that!",
  },
  {
    name: "Maryam J",
    company: "Resolvit Management and Technology Consulting India Pvt. Ltd.",
    p: `SpringVerify is very simple, reliable, and straightforward. The UX is awesome, checks are fast and most importantly all
			my queries are answered promptly without me having to wait for long.`,
  },
];

export default () => (
  <div className={`${styles.HealthCare} ${styles.m0}`}>
    <SEO
      title="Healthcare Background Screening Service  | SpringVerify India"
      desc="Screen candidates for Healthcare positions without any hassle using SpringVerify. Maintain patient safety by hiring only the best candidates through reliable background verification."
      url="https://in.springverify.com/industry/healthcare/"
      imgurl="https://assets-sv-in-lander.s3.ap-south-1.amazonaws.com/LP/seo/solution/SV-IN+(Healthcare).jpeg"
    />
    <div className={styles.topSection}>
      <Header />
      <Banner
        h1="Background Screening Solutions for the Healthcare Industry"
        p="When it comes to healthcare professionals -  safety and trust are at stake with every hire. Running background checks ensures your patients are entrusted with reliable medical practitioners and staff. "
        img="https://assets-sv-in.gumlet.io/LP/Healthcare/banner.png"
      />
    </div>
    <Brands
      list={["flipkart", "inshorts", "rently", "airmeet", "simpl", "aolloNew"]}
    />
    <AllCheck data={DataInfo.HealthCareAllCheck} />

    <CustomizedSolutions
      h2={"Instant Background Screening for \nHealthcare Professionals"}
      solutions={solutions2}
      path="Healthcare"
    />
    <section className={`${styles.customized} ${styles.plain}`}>
      <h2>
        Customized Solutions built for
        <br />
        Healthcare Professionals
      </h2>
      <div className={styles.cards}>
        {customized.map(({ h4, h6, p }, i) => (
          <div className={styles.card}>
            <div className={styles.text}>
              <img
                data-src={`https://assets-sv-in.gumlet.io/LP/Healthcare/Customized/${
                  i + 1
                }.svg`}
                alt=""
              />
              <h6>{h6}</h6>
              <h4>{h4}</h4>
              <p>{p}</p>
            </div>
          </div>
        ))}
      </div>
    </section>

    <Testimonials testimonials={testimonials} path="Employment" />
    <GetStarted
      img="Healthcare/get-started.svg"
      h3={
        "Transform your \nbackground verification \nexperience with \nSpringVerify"
      }
    />
    <Footer />
  </div>
);
