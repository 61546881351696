import React from "react";
import SectionTemplate from "./SectionTemplate";
import styles from "./landers.module.scss";

const links = [
  {
    span: "ID Checks",
    href: "/screenings/identity-verification/",
  },
  {
    span: "Criminal Record Check",
    href: "/screenings/criminal-record-check/",
  },
  {
    span: "Employment Verification",
    href: "/screenings/employment-verification/",
  },
  {
    span: "Education Verification",
    href: "/screenings/education-verification/",
  },
  {
    span: "Global Database Verification",
    href: "/screenings/global-database-verification/",
  },
  {
    span: "Court Record Verification",
    href: "/screenings/court-record-check/",
  },
  {
    span: "Reference Check",
    href: "/screenings/reference-verification/",
  },
  {
    span: "Address Verification",
    href: "/screenings/address-verification/",
  },
  {
    span: "Credit Check",
    href: "/screenings/credit-check/",
  },
  {
    span: "Social Media Verification",
    href: "/screenings/social-media-verification/",
  },
  {
    span: "Drug Test Screening",
    href: "/screenings/drug-test-verification/",
  },
  {
    span: "Passport Verification",
    href: "/screenings/passport-verification/",
  },
  {
    span: "DIN Verification",
    href: "/screenings/din-verification/",
  },
];

export default ({ h2, p, path }) => {
  const showLinks = path === "Operations" ? links : links.slice(0, 8);

  return (
    <div className={styles.bg}>
      <SectionTemplate img={`${path}/bg-check.svg`} h2={h2} p={p} reversed>
        <ul className={styles.links}>
          {showLinks.map(({ span, href }, i) => (
            <li key={i}>
              <a href={href}>
                <img
                  data-src={`https://assets-sv-in.gumlet.io/LP/Operations/Checks/${
                    i + 1
                  }.svg`}
                  alt=""
                />
                <span>{span}</span>
                <img data-src="https://assets-sv-in.gumlet.io/LP/Staffing/forward-arrow.svg" />
              </a>
            </li>
          ))}
        </ul>
      </SectionTemplate>
    </div>
  );
};
